<template>
  <svg :width="width" viewBox="0 0 34 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.834 40.946C24.3881 40.946 30.5118 34.7502 30.5118 27.1073C30.5118 19.4644 24.3881 13.2686 16.834 13.2686C9.28001 13.2686 3.15625 19.4644 3.15625 27.1073C3.15625 34.7502 9.28001 40.946 16.834 40.946Z"
      :fill="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.86364 0.0107422H30.1364C32.2668 0.0107422 34 1.74726 34 3.88171V9.30106V10.8495V48.0107H0V10.8495V9.30106V3.88171C0 1.74726 1.73323 0.0107422 3.86364 0.0107422ZM1.54545 9.30106V3.88171C1.54545 2.60119 2.58555 1.55913 3.86364 1.55913H30.1364C31.4145 1.55913 32.4545 2.60119 32.4545 3.88171V9.30106H1.54545ZM1.54545 10.8495V46.4624H32.4545V10.8495H1.54545Z"
      fill="#00042B"
    />
    <path
      d="M17 37.172C22.5389 37.172 27.0455 32.6569 27.0455 27.1075C27.0455 21.5581 22.5389 17.043 17 17.043C11.4611 17.043 6.95459 21.5581 6.95459 27.1075C6.95459 32.6569 11.4611 37.172 17 37.172ZM17 18.5914C21.6866 18.5914 25.5 22.412 25.5 27.1075C25.5 31.803 21.6866 35.6236 17 35.6236C12.3134 35.6236 8.50004 31.803 8.50004 27.1075C8.50004 22.412 12.3134 18.5914 17 18.5914Z"
      fill="#00042B"
    />
    <path
      d="M17 21.688V20.1396C14.4732 20.1396 12.1418 21.5154 10.9155 23.7311L12.2663 24.4821C13.2206 22.7588 15.0342 21.688 17 21.688Z"
      fill="#00042B"
    />
    <path
      d="M11.7616 25.7512L10.2641 25.3672C10.1196 25.9339 10.0454 26.52 10.0454 27.1076H11.5909C11.5909 26.6493 11.648 26.1933 11.7616 25.7512Z"
      fill="#00042B"
    />
    <path d="M29.3638 25.5586H27.8184V29.4296H29.3638V25.5586Z" fill="#00042B" />
    <path
      d="M2.70459 7.75258H18.1591V3.10742H2.70459V7.75258ZM9.0269 6.2042V4.65581H11.8368V6.2042H9.0269ZM16.7542 6.2042H13.2418V4.65581H16.7542V6.2042ZM4.10955 4.65581H7.62194V6.2042H4.10955V4.65581Z"
      fill="#00042B"
    />
    <path
      d="M22.7952 3.10742C21.5171 3.10742 20.4771 4.14949 20.4771 5.43C20.4771 6.71052 21.5171 7.75258 22.7952 7.75258C24.0733 7.75258 25.1134 6.71052 25.1134 5.43C25.1134 4.14949 24.0733 3.10742 22.7952 3.10742ZM22.7952 6.2042C22.3687 6.2042 22.0225 5.85658 22.0225 5.43C22.0225 5.00342 22.3687 4.65581 22.7952 4.65581C23.2218 4.65581 23.568 5.00342 23.568 5.43C23.568 5.85658 23.2218 6.2042 22.7952 6.2042Z"
      fill="#00042B"
    />
    <path
      d="M28.9774 3.10742C27.6993 3.10742 26.6592 4.14949 26.6592 5.43C26.6592 6.71052 27.6993 7.75258 28.9774 7.75258C30.2554 7.75258 31.2955 6.71052 31.2955 5.43C31.2955 4.14949 30.2554 3.10742 28.9774 3.10742ZM28.9774 6.2042C28.5508 6.2042 28.2046 5.85658 28.2046 5.43C28.2046 5.00342 28.5508 4.65581 28.9774 4.65581C29.4039 4.65581 29.7501 5.00342 29.7501 5.43C29.7501 5.85658 29.4039 6.2042 28.9774 6.2042Z"
      fill="#00042B"
    />
  </svg>
</template>

<script>
export default {
  name: 'WmSVG',

  props: {
    width: {
      type: Number,
      default: 34,
    },
    fillColor: {
      type: String,
      default: 'grey',
    },
  },
}
</script>
